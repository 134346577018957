import axios from "axios";

const ApiService = {
  init() {
    axios.defaults.headers.common["API_KEY"] = "123456789";
    axios.defaults.baseURL = "https://admin.giving.auk.edu.krd";
    // axios.defaults.baseURL = "http://auk-giving-backend.test";
  },

  async getPage(page) {
    try {
      const response = await axios.get("/api/v1/pages/" + page);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  async authorizePayment(data) {
    try {
      const response = await axios.post("/api/v1/pages/payment/process", data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  async doSubscribe(data) {
    try {
      const response = await axios.post("/api/v1/pages/doSubscribe", data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  async sendContact(data) {
    try {
      const response = await axios.post("/api/v1/pages/sendContact", data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  async areebaPayment(data) {
    try {
      const response = await axios.post("/api/v1/pages/areebaPayment", data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  async authorizePaymentSaveData(data) {
    try {
      const response = await axios.post("/api/v1/pages/authorizePaymentSaveData", data);
      return response;
    } catch (e) {
      return e.response;
    }
  },


};

export default ApiService;
