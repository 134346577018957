import { createWebHistory, createRouter } from "vue-router";
// mport axios from 'axios';
import routes from './routes';
import store from '../store';
import Vue from 'vue';

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...


// Navigation guard to send pageview events


export default router;