import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ApiService from "./services/api.service";
import store from "./store";
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/dist/style.css";
import VueSocialSharing from 'vue-social-sharing'
import VueGtag from "vue-gtag";

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

ApiService.init();

const app =createApp(App);

app.component("vue3-snackbar", Vue3Snackbar);
app.use(router);
app.use(VueGtag, {
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-XKVFJ1Y90N" }
});

// Make $gtag available globally


app.use(store);
app.use(SnackbarService);
app.use(VueSocialSharing);

app.config.globalProperties.$gtag=app._context.config.globalProperties.$gtag;



app.mount("#app");

// createApp(App).use(router).use(store).use(SnackbarService).mount("#app");
