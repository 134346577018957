// store/index.js
import { createStore } from "vuex";

import ApiService from "@/services/api.service.js";

const store = createStore({
  state() {
    return {
      showMobileMenu: false,
      activeTab:'home',
      amount:0,
      payment:[]

    };
  },
  mutations: {
    toggleShowMobileMenu(state) {
      state.showMobileMenu = !state.showMobileMenu;
    },
    closeMobileMenu(state) {
      state.showMobileMenu = false;
    },
    setActiveTab(state,tab){
      state.activeTab = tab;
    },
    setPayment(state,item){
      state.payment.push(item);
    },
    setAmount(state,amount){
      state.amount = amount;
    },
    clearPayment(state){
      state.payment = [];
      state.amount = 0;
    }
  },
  actions: {
    toggleShowMobileMenu(context) {
      context.commit("toggleShowMobileMenu");
    },
    closeMobileMenu(context) {
      context.commit("closeMobileMenu");
    },
    setActiveTab(context,tab){
      context.commit("setActiveTab",tab);
    },
    setPayment(context,payment){
      ApiService.authorizePayment(payment).then((response) => {
        return response
      });

      context.commit("clearPayment");
    }
  },
  getters: {
    showMobileMenu(state) {
      return state.showMobileMenu;
    },
  },
});

export default store;
